<template>
  <body>
    <article class="markdown-body essays">
      <h1>ESSAYS</h1>
      <ul>
        <li v-for="essay in essays" :key="essay.title">
          <router-link :to="`/essay/${essay.id}`">
            <h3>{{ essay.title }}</h3>
            <p>
              <em>{{ essay.description }}</em>
            </p>
          </router-link>
          <br />
        </li>
      </ul>
    </article>
  </body>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'EssaySelection',
  data() {
    return {
      essays: [
        {
          title: 'Five classifications for empathic capacity',
          id: 'empathic-capacity',
          description:
            'Some classifications for different levels of empathetic ability: willfully malicious, willfully ignorant, naively ignorant, statically empathetic, and dynamically empathetic.',
        },
      ],
    }
  },
}
</script>

<style scoped>
@import '../assets/styles/github-markdown.css';

.essays {
  text-align: center;
}
ul {
  list-style: none;
}
h3 {
  font-size: x-large;
}
.markdown-body {
  box-sizing: border-box;
  min-width: 200px;
  max-width: 980px;
  margin: 0 auto;
  padding: 45px;
}
p {
  color: gray;
}
</style>
