<template>
  <body>
    <article class="markdown-body">
      <div id="injected-html"></div>
    </article>
  </body>
</template>

<script>
import injectedHTML from '../assets/html/essay.html'
export default {
  name: 'EssaySubpage',
  mounted() {
    const injectedElement = document.getElementById('injected-html')
    injectedElement.innerHTML = injectedHTML
  },
}
</script>

<style scoped>
@import '../assets/styles/github-markdown.css';
</style>
