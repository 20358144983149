import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutView from '../views/AboutView.vue'
import EssaySelection from '../views/EssaySelection.vue' // home page for selecting essay
import EssayPageView from '../views/EssayPageView.vue' // container page for essay subpage
import EssaySubpage from '../views/EssaySubpage.vue' // the essay itself

export default createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView,
    },
    {
      path: '/about',
      name: 'about',
      component: AboutView,
    },
    {
      path: '/essays',
      name: 'essays',
      component: EssaySelection,
    },
    {
      path: '/essay',
      name: 'essay',
      component: EssayPageView,
      children: [
        {
          path: '/essay/empathic-capacity',
          name: 'empathic-capacity',
          component: EssaySubpage,
        },
      ],
    },
  ],
})
