<template>
  <div class="home">
    <h1>Redirecting...</h1>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  mounted() {
    window.location.replace('https://chopper2112.com/')
  },
}
</script>
